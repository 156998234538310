<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div>
    <b-card no-body class="mb-0">
      <!-- Loading -->
      <template v-if="spinner === true">
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <div class="text-center m-5">
              <b-spinner variant="primary" label="Text Centered"></b-spinner>
            </div>
          </div>
        </div>
      </template>

      <b-card v-else no-body class="mb-0 card-company-table">
        <!-- Search -->
        <div class="m-2">
          <b-row>
            <b-col cols="12" md="12">
              <div class="d-flex align-items-center justify-content-end">
                <b-button variant="primary" v-b-modal.modal-centered>
                  <span class="text-nowrap">Add New User</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <!-- Services Table -->
        <Table
          :items="users"
          :fields="fields"
          @editFunc="editUserData"
          @delFunc="delUserData"
        />
      </b-card>
    </b-card>

    <!--Model-->
    <div>
      <b-modal
        v-model="myModal"
        id="modal-centered"
        centered
        size="lg"
        header-class="text-uppercase"
        scrollable
        :title="isEdit ? '' : ''"
        hide-footer
      >
        <template #modal-header>
          <!-- Emulate built in modal header close button action -->
          <div
            class="
              w-100
              d-flex
              justify-content-between
              align-items-center
              text-uppercase
            "
          >
            <h5 class="mb-0" v-if="!isEdit">Add New User</h5>
            <h5 class="mb-0" v-if="isEdit">Edit User</h5>
            <b-button variant="outline-danger" @click="close"> X </b-button>
          </div>
        </template>

        <form ref="form" @submit.prevent="handleSubmit">
          <b-form-group>
            <!-- Title -->
            <b-row class="mb-2">
              <b-col>
                <div>
                  <label for="title" class="font-weight-bolder">Name :</label>
                  <b-form-input
                    v-model="postForm.name"
                    id="title"
                    placeholder="Enter User Name"
                    required
                  >
                  </b-form-input>
                </div>
              </b-col>
            </b-row>

            <!-- Email -->
            <b-row class="mb-2">
              <b-col>
                <div>
                  <label for="email" class="font-weight-bolder">Email :</label>
                  <b-form-input
                    v-model="postForm.email"
                    id="email"
                    type="email"
                    placeholder="Enter User Email"
                    required
                  >
                  </b-form-input>
                </div>
              </b-col>
            </b-row>

            <!-- Role -->
            <b-row class="mb-2">
              <b-col>
                <div>
                  <label for="role" class="font-weight-bolder">Role :</label>
                  <b-form-input
                    v-model="postForm.role"
                    id="role"
                    placeholder="Enter User Role"
                    required
                  >
                  </b-form-input>
                </div>
              </b-col>
            </b-row>
            
            <!-- Photo -->
            <div>
              <MyPhotoSingleUpload
                :isEdit="isEdit"
                :editPhotoUrl="photoUrl"
              />
            </div>
          </b-form-group>

          <div class="w-100 text-center" v-if="!isEdit">
            <b-button variant="primary" type="submit" class="col-6">
              <b-spinner
                variant="white"
                v-if="spinner === true"
                label="Text Centered"
              ></b-spinner>
              <span v-else>Submit</span>
            </b-button>
          </div>

          <div class="w-100 text-center" v-if="isEdit">
            <b-button
              type="button"
              variant="primary"
              @click="updateUserData(postForm.id)"
              class="col-6"
            >
              <b-spinner
                variant="white"
                v-if="spinner === true"
                label="Text Centered"
              ></b-spinner>
              <span v-else>Update</span>
            </b-button>
          </div>
        </form>
      </b-modal>
    </div>
    <!--Model-->
  </div>
</template>
  
  <script>
import store from "@/store";
import Table from "../components/Table.vue";
import MyPhotoSingleUpload from "../components/MyPhotoSingleUpload.vue";
import { ref, reactive } from "@vue/composition-api";
import {
  BFormInput,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BCard,
  BButton,
  BSpinner,
  BRow,
  BCol,
  BModal,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";

export default {
  components: {
    BFormInput,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BModal,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BFormCheckbox,
    Table,
    MyPhotoSingleUpload,
  },
  emits: ["input"],
  setup(props, { emit }) {
    const fields = [
      { key: "id", label: "#" },
      { key: "name", label: "name" },
      { key: "email", label: "email" },
      { key: "role_name", label: "role" },
    //   { key: "photo_url", label: "photo" },
      { key: "actions", label: "actions" },
    ];
    const spinner = ref(false);
    const myModal = ref(false);
    const isEdit = ref(false);
    const photoUrl = ref("");
    const users = ref([]);
    const postForm = reactive({
      id: "",
      name: "",
      email: "",
      role: "",
      profile_picture: null,
    });

    //Datas Fetching
    const fetchUsers = () => {
      spinner.value = true;
      store
        .dispatch("app-mypages/fetchDatas", "users")
        .then((response) => {
          users.value = response.data.data;
          spinner.value = false;
        });
    };
    fetchUsers();

    //Functions
    //Data Posting
    const handleSubmit = () => {
      spinner.value = true;
      store
        .dispatch("app-mypages/postDatas", {
          path: "me",
          payload: postForm,
        })
        .then((response) => {
          spinner.value = false;
          postForm.name = "";
          postForm.email = "";
          postForm.role = "";
          postForm.profile_picture = null;
          fetchUsers();
          myModal.value = false;
          isEdit.value = false;
        });
    };

    // Data Editing
    const editUserData = (item) => {
      myModal.value = true;
      isEdit.value = true;

      postForm.id = item.id;
      postForm.name = item.name;
      postForm.email = item.email;
    //   photoUrl.value = item.photo.name;
      postForm.role = item.role;
    };
    const updateUserData = (id) => {
      spinner.value = true;
      store
        .dispatch("app-mypages/updateData", {
          path: "users",
          payload: postForm,
        })
        .then((response) => {
          fetchCategories();
          spinner.value = false;
          myModal.value = false;
          isEdit.value = false;
        });
    };

    // Data Deleting
    const delUserData = (id) => {
      spinner.value = true;
      store
        .dispatch("app-mypages/delData", { path: "users", id })
        .then((response) => {
          fetchUsers();
          spinner.value = false;
        });
    };

    // Close Function
    const close = () => {
      myModal.value = false;
      isEdit.value = false;

      postForm.id = "";
      postForm.name = "";
      postForm.email = 0;
      postForm.role = "";
      postForm.profile_picture = null;
    };

    return {
      fields,
      spinner,
      users,
      myModal,
      isEdit,
      handleSubmit,
      photoUrl,
      postForm,
      editUserData,
      updateUserData,
      delUserData,
      close,
    };
  },
};
</script>